import React from 'react';
import { Chart as ChartJS, LinearScale, Legend } from "chart.js/auto";
import { Line } from 'react-chartjs-2';

const AnalyticsLineChart = () => {
  ChartJS.register(LinearScale, Legend);

  const months = ['Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];

  const data = {
    labels: months,
    datasets: [
      {
        label: 'Online',
        data: [150, 200, 180, 220, 240, 260, 280, 300, 320, 340, 360, 380],
        backgroundColor: "#21A62C",
        borderColor: "#21A62C"
      },
      {
        label: 'Offline',
        data: [100, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220],
        backgroundColor: "#260F99",
        borderColor: "#260F99",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        align: 'center',
        labels: {
          padding: 30, // Adjust padding around the legend
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      title: {
        display: true,
        align: 'start',
        text: 'Bookings',
        color: '#1C1154',
        font: {
          size: 24,
          family: 'Poppins',
        },
      },
      tooltip: {
        enabled: true,
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        type: "category",
        position: 'bottom',
      },
      xTopPadding: {
        // Fake x-axis for padding
        position: 'top',
        labels: [''],
        grid: {
          display: false,
        },
        border: {
            color: '#ffffff'
        }
      },
      y: {
        beginAtZero: true,
        type: "linear",
        grid: {
            display: false,
          },
      },
    },
  };


  return (
    <div style={ { width: '72%', height: '100%', backgroundColor: '#FFFFFF', paddingLeft: '1.35%', paddingTop: '1%' } }>
      <Line data={data} options={options} />
    </div>
  );
};

export default AnalyticsLineChart;