import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './../styling/BusinessClientView.css'
import LeftClientPanel from "./panels/LeftClientPanel";
import GalleryTab from "./tabs/GalleryTab";
import InvoiceTab from "./tabs/InvoiceTab";
import OverviewTab from "./tabs/OverviewTab";
import PackagesTab from "./tabs/PackagesTab";
import QuestionnaireTab from "./tabs/QuestionnaireTab";
import TemplateTab from "./tabs/TemplateTab";

const BusinessClientView = ({selectedClient, handleBacktoAccount}) => {
  //left client Panel add clientData and update left client panel for that data
    return(
        <div style={{ display: 'flex'}}>
            <div className="clientProfileDetails">
                <LeftClientPanel handleBackToAccount={handleBacktoAccount}/>
            </div>
            <div style={{ marginTop: '20px', width: '60%'}}>
            <Tabs>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Invoice</Tab>
              <Tab>Packages</Tab>
              <Tab>Questionnaire</Tab>
              <Tab>Template</Tab>
              <Tab>Gallery</Tab>
            </TabList>

            <TabPanel>
             <OverviewTab/>
            </TabPanel>
            <TabPanel>
              <InvoiceTab/>
            </TabPanel>
            <TabPanel>
              <PackagesTab/>
            </TabPanel>
            <TabPanel>
              <QuestionnaireTab/>
            </TabPanel>
            <TabPanel>
              <TemplateTab/>
            </TabPanel>
            <TabPanel>
              <GalleryTab/>
            </TabPanel>
          </Tabs>
          </div>
        </div>
     );
}

export default BusinessClientView;