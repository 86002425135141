import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, loginFailure } from '../authSlice';
import '../styling/SignUp.css';
import { useNavigate } from 'react-router-dom';

const Login = ({handleIsclient}) => {

  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');

      const handleLogin = async () => {
        try {
          const response = await axios.post('http://localhost:3001/business-user/login', {
            email,
            password
          }, { withCredentials: true },
        );
          dispatch(loginSuccess(response.data));
          console.log(response.data);
          const isClient = false; 
      // Call handleIsclient to update parent state
      handleIsclient(isClient);

      // Navigate with isClient flag set to true
      navigate(`/?isClient=${isClient}`);
        } catch (error) {
          dispatch(loginFailure(error.message));
        }
      };


    
      return (
        <div className='login'>
          <div style={{ padding: '13%' }}>
            <div style={{ fontSize: '35px', color: '#1C1154', marginBottom: '20px' }}>
              Login
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="login-input"
              value={email}
              onChange={(e) => setUsername(e.target.value)}
            />

            <input
              type="text"
              name="password"
              placeholder="Password"
              className="login-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div style={{ paddingLeft: '30%', color: '#545454'}}>
            <p><span style={{ textDecoration: 'underline' }}>forgot password? </span></p>
            </div>
            <button className="login-button" onClick={handleLogin}>
              Login
            </button>
        </div>
        </div>
      );
};

export default Login;
