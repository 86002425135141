import React, { useState } from "react";
import "../styling/Account.css";
import profilePicture from "../images/John-Wick.jpg.avif";
import logo from "../images/logo.webp";
import { FaUpload } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { PiLinkBold } from "react-icons/pi";
import { ImDownload3 } from "react-icons/im";
import ProductModal from "./modals/ProductModal";
import ColorPicker from "./ColorPicker";
import { GoSmiley } from "react-icons/go";
import CreateBookingModal from "./modals/CreateBookingModal";

const CompanyInfo = () => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [divColor, setDivColor] = useState("green");
  const [tempColor, setTempColor] = useState("green");

  const togglePicker = () => {
    setIsPickerVisible(!isPickerVisible);
    setTempColor(divColor);
  };

  const handleCancel = () => {
    setIsPickerVisible(false);
  };

  const handleChoose = (selectedColor) => {
    setDivColor(selectedColor);
    setIsPickerVisible(false);
  };

  const smiley = (
    <div style={{ marginRight: "8px", marginTop: "4px" }}>
      <GoSmiley />
    </div>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    console.log("on clock method");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ width: "80%" }}>
      <div className="accountInfo">
        <div>
          {" "}
          <h2>Company Profile</h2>{" "}
        </div>
        <div
          className="accountButtons"
          style={{ width: "35%", marginRight: "0w.5%" }}
        >
          <button
            style={{
              height: "40px",
              fontSize: "14px",
              background: "#FFFFFF",
              color: "#1C1154",
            }}
          >
            {" "}
            Account
          </button>
          <button style={{ height: "40px", fontSize: "14px" }}> Company</button>
          <button
            style={{
              height: "40px",
              fontSize: "14px",
              background: "#FFFFFF",
              color: "#1C1154",
            }}
          >
            {" "}
            Book-A-Booth
          </button>
        </div>
      </div>

      <div
        style={{
          height: "auto",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Company Branding
        </h2>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "50px",
            marginTop: "30px",
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              src={profilePicture}
              alt="Profile"
              style={{ width: "70px", height: "70px", borderRadius: "50%" }}
            />
            <div
              className="accountButtons"
              style={{
                gap: "17px",
                marginLeft: "4%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div>
                <div style={{}}>
                  {isPickerVisible && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100px", // Adjust based on where you want the picker to show
                        left: "40%",
                        transform: "translateX(-50%)",
                        zIndex: 999,
                        backgroundColor: "white",
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                      }}
                    >
                      <ColorPicker
                        initialColor={tempColor}
                        onCancel={handleCancel}
                        onChoose={handleChoose}
                      />
                    </div>
                  )}
                </div>
                <button
                  style={{
                    width: "60%",
                    height: "45px",
                    fontSize: "18px",
                    background: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    color: "#1C1154",
                  }}
                >
                  <FaUpload /> Profile Image
                </button>

                <div style={{ marginTop: "15px" }}>
                  Upload png, jpg, or svg images to 256x256 px.
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                backgroundColor: divColor,
              }}
            />

            <div
              className="accountButtons"
              style={{
                gap: "17px",
                marginLeft: "4%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div>
                <button
                  style={{
                    width: "100%",
                    height: "45px",
                    fontSize: "18px",
                    background: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    color: "#1C1154",
                    marginBottom: "30px",
                  }}
                  onClick={togglePicker}
                >
                  Button Color
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <img
              src={logo}
              alt="Profile"
              style={{ width: "70px", height: "70px" }}
            />
            <div
              className="accountButtons"
              style={{
                gap: "17px",
                marginLeft: "4%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div>
                <button
                  style={{
                    width: "60%",
                    height: "45px",
                    fontSize: "18px",
                    background: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    color: "#1C1154",
                  }}
                >
                  <FaUpload /> Upload Logo
                </button>
                <div style={{ marginTop: "15px" }}>
                  Upload png, jpg, or svg images to 256x256 px.
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                backgroundColor: "black",
              }}
            />
            <div
              className="accountButtons"
              style={{
                gap: "17px",
                marginLeft: "4%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div>
                <button
                  style={{
                    width: "100%",
                    height: "45px",
                    fontSize: "18px",
                    background: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    color: "#1C1154",
                    marginBottom: "30px",
                  }}
                >
                  Button Text Color
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "400px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Company Info
        </h2>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Business Name
            </div>
            <input
              placeholder="John"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Website
            </div>
            <input
              placeholder="Doe"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Email
            </div>
            <input
              placeholder="JohnDoe@gamail.com"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Phone Number
            </div>
            <input
              placeholder="(000) 000-0000"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Street
          </div>
          <input
            placeholder="(000) 000-0000"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            City, State
          </div>
          <input
            placeholder="(000) 000-0000"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Zip Code
          </div>
          <input
            placeholder="(000) 000-0000"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
      </div>
      <div
        style={{
          height: "346px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Team Members
        </h2>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              First Name
            </div>
            <input
              placeholder="John"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Last Name
            </div>
            <input
              placeholder="Doe"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Email
            </div>
            <input
              placeholder="John"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Phone Number
            </div>
            <input
              placeholder="Doe"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "35px" }}>
          <img
            src={profilePicture}
            alt="Profile"
            style={{ width: "70px", height: "70px", borderRadius: "50%" }}
          />
          <div
            className="accountButtons"
            style={{
              gap: "17px",
              marginLeft: "4%",
              width: "100%",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "20%",
                height: "40px",
                fontSize: "14px",
                background: "#FFFFFF",
                border: "1px solid #D9D9D9",
                color: "#1C1154",
              }}
            >
              {" "}
              <FaUpload /> Upload Photo
            </button>
            <button
              style={{
                width: "27%",
                height: "40px",
                fontSize: "14px",
                background: "#F2F2F2",
                border: "1px solid #D9D9D9",
                color: "#1C1154",
              }}
            >
              {" "}
              <FaTrash /> Delete Team Member
            </button>
          </div>
        </div>
        <button
          className="addTeamMemberButton"
          style={{ marginTop: "20px", width: "25%" }}
        >
          {" "}
          {smiley} Add Team Member{" "}
        </button>
      </div>
      <div
        style={{
          height: "360px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Share Links
        </h2>
        <h4 style={{ color: "#1C1154" }}>Client Portal</h4>
        <h5 style={{ color: "#4D4D4D", fontSize: "15px" }}>
          Paste this link to your website for your clients to access their
          portals.
        </h5>
        <div style={{ display: "flex", justifyItems: "baseline" }}>
          <PiLinkBold style={{ fontSize: "25px" }} />{" "}
          <div
            style={{ paddingTop: "3px", paddingLeft: "4px", color: "#1C1154" }}
          >
            www.boothbloom.com/account
          </div>
        </div>

        <h4 style={{ color: "#1C1154" }}>Book-A-Booth</h4>
        <h5 style={{ color: "#4D4D4D", fontSize: "15px" }}>
          Share links or paste on your website or directly to you contacts.
        </h5>
        <div style={{ display: "flex", justifyItems: "baseline" }}>
          <PiLinkBold style={{ fontSize: "25px" }} />{" "}
          <div
            style={{ paddingTop: "3px", paddingLeft: "4px", color: "#1C1154" }}
          >
            www.boothbloom.com/book-a-booth
          </div>
        </div>
      </div>
      <div
        style={{
          height: "260px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "50px" }}
        >
          Membership
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ color: "#1C1154" }}>Unlimited Monthly</h4>
          <button
            onClick={openModal}
            style={{
              height: "40px",
              fontSize: "14px",
              background: "#FFFFFF",
              color: "#1C1154",
              border: "1px solid #D9D9D9",
              width: "15%",
              cursor: "pointer",
            }}
          >
            {" "}
            Change Plan{" "}
          </button>
        </div>
        <ProductModal isModalOpen={isModalOpen} closeModal={closeModal} />
        <div
          style={{
            color: "#4D4D4D",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h5>
            All features, no limits. Unlimited support. Unlimited team members.{" "}
            <br /> 3% credit card of 1.5% ACH fee.
          </h5>
          $25/month
        </div>
        <h5 style={{ color: "#4D4D4D", fontSize: "15px" }}>
          Next payment: $25 on Dec 12, 2022
        </h5>
      </div>
      <div
        style={{
          height: "230px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "35px" }}
        >
          Billing
        </h2>
        <div
          style={{
            color: "#4D4D4D",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4>Payment Method</h4>
          Credit Card
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Card Number
          </div>
          <input
            placeholder="*************"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
      </div>
      <div
        style={{
          height: "260px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2 style={{ marginTop: "30px", color: "#1C1154", fontWeight: "450" }}>
          Invoice History
        </h2>
        <div>
          <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "space-between",
              padding: "2.3%",
              borderBottom: "1px solid #D9D9D9",
              paddingRight: "7%",
            }}
          >
            <div>Date</div>
            <div style={{ marginLeft: "40px" }}>Plan</div>
            <div>Amount</div>
            <div>Download Invoice</div>
          </div>
          <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "space-between",
              padding: "1.3%",
              paddingRight: "20%",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <div>01/01/2024</div>
            <div>Starter</div>
            <div>$25.00</div>
            <div>
              <ImDownload3 />{" "}
            </div>
          </div>
          <div
            style={{
              color: "#4D4D4D",
              display: "flex",
              justifyContent: "space-between",
              padding: "1.3%",
              borderBottom: "1px solid #D9D9D9",
              paddingRight: "20%",
            }}
          >
            <div>01/01/2024</div>
            <div>Starter</div>
            <div>$25.00</div>
            <div>
              <ImDownload3 />{" "}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "300px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Bank Info
        </h2>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Business Type
          </div>
          <input
            placeholder="*************"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Bank Account
          </div>
          <input
            placeholder="*************"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              color: "#4D4D4D",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            {" "}
            Business Owner
          </div>
          <input
            placeholder="John Doe"
            style={{
              border: "hidden",
              borderBottom: "2px solid #D9D9D9",
              fontSize: "15px",
              width: "100%",
            }}
          ></input>
        </div>
        <button
          style={{
            marginTop: "35px",
            height: "40px",
            width: "30%",
            background: "#FFFFFF",
            border: "1px solid #D9D9D9 ",
            color: "#1C1154",
            fontSize: "17px",
            fontWeight: "450",
          }}
        >
          Change Bank Info
        </button>
      </div>
    </div>
  );
};

export default CompanyInfo;
