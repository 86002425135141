import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, loginFailure } from '../../authSlice';
import '../../styling/SignUp.css';
import '../../styling/PaymentSuccess.css';
import '../../styling/ClientPortal.css'

import { useNavigate } from 'react-router-dom';

const ClientLogin = () => {

  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');

      const handleLogin = async () => {
        try {
          const response = await axios.post('http://localhost:3001/client-user/login', {
            email,
            password
          }, { withCredentials: true },
        );
          dispatch(loginSuccess(response.data));
          navigate('/client-home');
        } catch (error) {
          dispatch(loginFailure(error.message));
        }
      };

      const boothRightPanel = (
        <div
          className="success-right-panel"
          style={{
            width: "19%",
            background: "#FFFFFF",
            marginTop: "145px",
            height: "fitContent",
            borderLeft: 'hidden',

          }}
        >
          <div
            className="right-panel-content"
            style={{ marginTop: "0px", padding: "20px" }}
          >
            <div className="right-panel-arrow-container">
              <span>
                <h4
                  style={{
                    color: "#1C1154",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "425",
                  }}
                >
                  Bronze Package
                </h4>
              </span>{" "}
              <h4
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginBottom: "5px",
                  color: "#1C1154",
                  fontWeight: "425",
                  marginTop: "auto",
                }}
              >
                {" "}
                $500.00
              </h4>
            </div>
          </div>
          <div
            className="right-panel-content"
            style={{ marginTop: "20px", padding: "20px" }}
          >
            <div className="right-panel-arrow-container">
              <span>
                <h4
                  style={{
                    color: "#1C1154",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "425",
                  }}
                >
                  Custom Party Props
                </h4>
              </span>{" "}
              <h4
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginBottom: "5px",
                  color: "#1C1154",
                  fontWeight: "425",
                  marginTop: "auto",
                }}
              >
                {" "}
                $50.00
              </h4>
            </div>
          </div>
            <div>
              <p
                style={{
                  color: "#625C73",
                  marginTop: "20px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                Nov 23, 2023
              </p>
          </div>
            <div>
              <p
                style={{
                  color: "#625C73",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                12:00 PM to 04:00 PM
              </p>
          </div>
    
          
            <div>
              <p
                style={{
                  color: "#625C73",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight: "425",
                }}
              >
                1234 Kumquat St. Riverside, CA 92503
              </p>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", color: "#4D4D4D" }}
          >
            <p>Payment recieved successfully! </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#4D4D4D",
            }}
          >
            <div> Sliver Package</div>
            <div> $500</div>
          </div>
    
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#4D4D4D",
              marginTop: "10px",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <div> Custom Party Props</div>
            <div style={{ marginBottom: "5px" }}> $50</div>
          </div>
    
          <div
            style={{
              height: "80px",
              backgroundColor: "#FFFFFF",
              marginBottom: "20px",
              paddingTop: "5%",
              width: "100%",
            }}
          >
            <div
            style={{ display: "flex", justifyContent: "center", color: "#4D4D4D", fontWeight: 'bold' }}
          >
            <p>Total Amount:  $550 </p>
          </div>
          </div>
        </div>
      );
    
      return (
        <div className='paymentSuccess'>
        <div className='login'>
          <div style={{ padding: '13%' }}>
            <div style={{ fontSize: '35px', color: '#1C1154', marginBottom: '20px' }}>
            Your booking is confirmed!
            </div>
            <div style={{fontSize: '15px', color: '#4D4D4D', }}>
            We emailed the detailed to boothcompany@email.com
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="login-input"
              value={email}
              onChange={(e) => setUsername(e.target.value)}
            />

            <input
              type="text"
              name="password"
              placeholder="Password"
              className="login-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div style={{ paddingLeft: '30%', color: '#545454'}}>
            <p><span style={{ textDecoration: 'underline' }}>forgot password? </span></p>
            </div>
            <button className="login-button" onClick={handleLogin}>
              Login
            </button>
        </div>
        </div>
        {boothRightPanel}
        </div>
      );
};

export default ClientLogin;







