// Header.js
import React, { useState, useEffect } from "react";
import "../styling/Header.css";
import logo from "../images/BloomBoothLogo.png";
import profilePic from "../images/profilePic.jpg";
import { IoNotifications } from "react-icons/io5";
import CreateBookingModal from "./modals/CreateBookingModal";
import { useSelector } from "react-redux";
import data from "./data.json";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

const Header = ({ onClickAccountInfo }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showCreateDropdown, setShowCreateDropdown] = useState(false);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const [selectedItem, setSelectedItem] = useState("Clients");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const toggleProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  const handleSearchBarChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const toggleSearchCriteria = () => {
    setSelectedItem((prevItem) =>
      prevItem === "Clients" ? "Events" : "Clients"
    );
    setShowSearchDropdown(false);
  };

  const toggleSearchDropdown = () => {
    setShowSearchDropdown(!showSearchDropdown);
  };

  const toggleCreateDropdown = () => {
    setShowCreateDropdown(!showCreateDropdown);
  };

  const filterResults = (query) => {
    if (query) {
      const results =
        selectedItem === "Clients"
          ? data.clients.filter((client) =>
              client.toLowerCase().includes(query.toLowerCase())
            )
          : data.events.filter((event) =>
              event.name.toLowerCase().includes(query.toLowerCase())
            );
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  };

  const openNewBookingsModal = () => {
    toggleCreateDropdown();
    setIsModalOpen(true);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {" "}
      {isLoggedIn && (
        <header className="header" style={{ height: "50px" }}>
          <div style={{ display: "flex", width: "60%", marginTop: "-11px" }}>
            <img style={{ marginLeft: "1%", width: "15%" }} src={logo} alt="" />
            <div
              style={{
                fontSize: "15px",
                marginTop: "12px",
                border: "1px solid #D9D9D9",
                paddingTop: "9.5px",
                height: "24px",
                marginLeft: "13px",
                paddingLeft: "12px",
                paddingRight: "12px",
                borderRight: "hidden",
                paddingBottom: "3px",
              }}
            >
              {selectedItem}{" "}
              <button onClick={toggleSearchDropdown}>
                {" "}
                <span>&#9662;</span>{" "}
              </button>
            </div>
            {showSearchDropdown && (
              <div className="searchButton-dropdown">
                <div>
                  {" "}
                  <button
                    style={{
                      fontSize: "15px",
                      paddingTop: "10px",
                      paddingLeft: "11px",
                      backgroundColor: "#FFFFFF",
                      borderStyle: "none",
                    }}
                    onClick={toggleSearchCriteria}
                  >
                    {selectedItem === "Clients" ? "Events" : "Clients"}
                  </button>
                </div>
              </div>
            )}

            <div
              style={{
                position: "relative",
                width: "250px",
                marginTop: "13px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "13px",
                  color: "gray",
                }}
              >
                {/* <FaSearch /> */}
              </div>
              <input
                type="text"
                style={{ padding: "10px", paddingLeft: "33px", width: "100%" }}
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchBarChange}
              />

              {searchQuery && ( // Conditionally render search results
                <div
                  className="search-results"
                  style={{
                    position: "absolute",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                    marginTop: "5px",
                    width: "100%",
                    zIndex: "1",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {filteredResults.length > 0 ? ( // Display filtered results
                    filteredResults.map((result, index) => (
                      <div
                        key={index}
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          color: "#1C1154",
                        }}
                      >
                        <div>
                          {selectedItem === "Clients" ? result : result.name}
                        </div>
                        {selectedItem === "Events" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: "6px",
                            }}
                          >
                            <div style={{ fontSize: "12px", color: "gray" }}>
                              {result.date} - {result.client}
                            </div>
                            {/* <div style={{ fontSize: "12px", color: "gray" }}>{result.client}</div> */}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div style={{ padding: "10px" }}>No results found</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "17rem",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div style={{ marginBottom: "3px", width: "10rem" }}>
              <button
                className="createButtonStyle"
                onClick={toggleCreateDropdown}
              >
                Create New
              </button>
              {showCreateDropdown && (
                <div className="createButton-dropdown">
                  <button
                    className="createNewBookingStyle"
                    onClick={openNewBookingsModal}
                  >
                    {" "}
                    New Booking
                  </button>
                </div>
              )}
            </div>
            <div className="notification">
              <IoNotifications
                style={{ fontSize: "24px", color: "rgb(29,16,83)" }}
              />
            </div>
            <div className="profile" onClick={toggleProfileDropdown}>
              <img src={profilePic} alt="" />
              {showProfileDropdown && (
                <div className="profile-dropdown">
                  <ul>
                    <li>
                      <button
                        style={{ border: "hidden", backgroundColor: "#FFFFFF" }}
                        onClick={() => onClickAccountInfo("account")}
                      >
                        Account
                      </button>
                    </li>
                    <li>
                      <button
                        style={{ border: "hidden", backgroundColor: "#FFFFFF" }}
                        onClick={() => onClickAccountInfo("company")}
                      >
                        Company
                      </button>
                    </li>
                    <li>
                      <button
                        style={{ border: "hidden", backgroundColor: "#FFFFFF" }}
                        onClick={() => onClickAccountInfo("booth")}
                      >
                        Book-A-Booth
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <CreateBookingModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
            />
          </div>
        </header>
      )}
    </div>
  );
};

export default Header;
