import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../styling/ClientPortal.css'; // Ensure this file exists and the path is correct
import LeftClientPanel from "../panels/LeftClientPanel"; // Ensure this file exists
import GalleryTab from "../tabs/GalleryTab"; // Ensure this file exists
import InvoiceTab from "../tabs/InvoiceTab"; // Ensure this file exists
import OverviewTab from "../tabs/OverviewTab"; // Ensure this file exists
import PackagesTab from "../tabs/PackagesTab"; // Ensure this file exists
import QuestionnaireTab from "../tabs/QuestionnaireTab"; // Ensure this file exists
import TemplateTab from "../tabs/TemplateTab"; // Ensure this file exists
import axios from 'axios';
const ClientPortal = ({ selectedClient, handleBacktoAccount }) => {

   const [ client, setClient  ] = useState([]);

   const [ brandingColours, setBrandingColours] = useState({primary: '#008000', seecondary: '#dddddd'})

    console.log(client);
    useEffect(() => {
        fetchClientData();
      }, []);


      const fetchClientData = async () => {
        try {
          const response = await axios.get('http://localhost:3001/client-user', { withCredentials: true });
          console.log(response.data.data);
          setClient(response.data.data);
        } catch (error){
          console.log('Error fetching dashboard chart data... ', error);
        }
      };
    return (
        <div style={{ display: 'flex' }}>
            <div className="clientProfileDetails" >
                <LeftClientPanel handleBackToAccount={handleBacktoAccount}
                  clientdata={client ? client : []}
                  brandingColours={brandingColours}
                /> 
            </div>
            <div className="clientLandingTabs" style={{marginLeft: '20px'}}>
                {/* <Header onClickAccountInfo={() => {}} /> */}
                <Tabs>
                    <TabList>
                        <Tab>Overview</Tab>
                        <Tab>Invoice</Tab>
                        <Tab>Packages</Tab>
                        <Tab>Questionnaire</Tab>
                        <Tab>Template</Tab>
                        <Tab>Gallery</Tab>
                    </TabList>

                    <TabPanel>
                        <OverviewTab brandingColours={brandingColours} />
                    </TabPanel>
                    <TabPanel>
                        <InvoiceTab />
                    </TabPanel>
                    <TabPanel>
                        <PackagesTab />
                    </TabPanel>
                    <TabPanel>
                        <QuestionnaireTab />
                    </TabPanel>
                    <TabPanel>
                        <TemplateTab />
                    </TabPanel>
                    <TabPanel>
                        <GalleryTab />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default ClientPortal;