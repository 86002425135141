import React from "react";
import { Doughnut } from "react-chartjs-2";
import "../../styling/Donut.css";

const AnalyticsDonut = () => {

  const data = {
    labels: ["Booked         ", "Non-booked  ", "Cancelled    "],
    datasets: [
      {
        data: [50, 45, 5],
        backgroundColor: ["#260F99", "#B5A6FF", "#D9D9D9"],
        hoverBackgroundColor: ["#260F99", "#B5A6FF", "#D9D9D9"],
      },
    ],
  };

  return (
    <div className="right-donut">
      <h2 style={{ paddingTop: "20px", paddingLeft: '30px', fontWeight: '500', color: '#1C1154' }}>Success Rate</h2>
      <div className="donut">
        <Doughnut
          style={{ width: '250px', height: '230px'}}
          data={data}
          options={{
            cutout: '80%',
            plugins: {
              legend: {
                display: true,
                position: "bottom", // Position the legend below the chart
                labels: {
                    generateLabels: (chart) => {
                        const data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                          return data.labels.map((label, i) => {
                            const value = data.datasets[0].data[i];
                            const backgroundColor = data.datasets[0].backgroundColor[i];
                            return {
                              text: `${label}: ${value}%`, // Display label with value
                              fillStyle: backgroundColor,
                              strokeStyle: backgroundColor,
                              lineWidth: 0.5,
                            };
                          });
                        }
                        return [];
                      },
                  boxWidth: 12,
                  padding: 20, // Adjust padding between legend items
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default AnalyticsDonut;
