import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../styling/ClientPortal.css'; // Ensure this file exists and the path is correct
import LeftClientPanel from "../panels/LeftClientPanel"; // Ensure this file exists
import GalleryTab from "../tabs/GalleryTab"; // Ensure this file exists
import InvoiceTab from "../tabs/InvoiceTab"; // Ensure this file exists
import OverviewTab from "../tabs/OverviewTab"; // Ensure this file exists
import PackagesTab from "../tabs/PackagesTab"; // Ensure this file exists
import QuestionnaireTab from "../tabs/QuestionnaireTab"; // Ensure this file exists
import TemplateTab from "../tabs/TemplateTab"; // Ensure this file exists
import axios from 'axios';
import EventsTable from "../tables/EventsTable";
import ClientEventsTable from "../tables/ClientEventsTable";
const ClientLandingPage = ({ selectedClient, handleBacktoAccount }) => {

   const [ client, setClient  ] = useState([]);

   const [events, setEvents] = useState([]);
   const [ brandingColours, setBrandingColours] = useState({primary: '#008000', seecondary: '#dddddd'})



    useEffect(() => {
        fetchClientData();
        fetchEventsData();
      }, []);


      const fetchClientData = async () => {
        try {
          const response = await axios.get('http://localhost:3001/client-user', { withCredentials: true });
          setClient(response.data.data);
        } catch (error){
          console.log('Error fetching dashboard chart data... ', error);
        }
      };

      const fetchEventsData = async () => {
        try {
          const response = await axios.get('http://localhost:3001/business/events', { withCredentials: true });
          setEvents(response.data.data);
        } catch (error){
          console.log('Error fetching dashboard chart data... ', error);
        }
      };

    return (
        <div style={{ display: 'flex' }}>
            <div className="clientProfileDetails">
                <LeftClientPanel handleBackToAccount={handleBacktoAccount}
                  clientData = {client}
                  brandingColours={brandingColours}
                /> 
            </div>
            <div className="clientLandingTabs">
                {/* <Header onClickAccountInfo={() => {}} /> */}
                <Tabs>
                    <TabList>
                        <Tab>Events</Tab>
                        <Tab>Gallery</Tab>
                    </TabList>

                    <TabPanel>
                        <ClientEventsTable event={events} brandingColours={brandingColours}/>
                    </TabPanel>
                    <TabPanel>
                        <GalleryTab />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default ClientLandingPage;