import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ initialColor, onChoose, onCancel }) => {
  const [color, setColor] = useState(initialColor || '#00ff00'); // Default to green

  const handleChangeComplete = (newColor) => {
    setColor(newColor.hex);
  };

  return (
    <div>
      <SketchPicker
        color={color}
        onChangeComplete={handleChangeComplete}
      />
      <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
        <button
          style={{
            padding: '8px 16px',
            background: '#D9D9D9',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          style={{
            padding: '8px 16px',
            background: '#1C1154',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => onChoose(color)}
        >
          Choose
        </button>
      </div>
    </div>
  );
};

export default ColorPicker;
